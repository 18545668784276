import { Controller } from '@hotwired/stimulus';
import { basicSetup, EditorView } from 'codemirror';
import { ruby } from '@codemirror/legacy-modes/mode/ruby';
import { yaml } from '@codemirror/legacy-modes/mode/yaml';
import { StreamLanguage } from '@codemirror/language';
import { EditorState } from '@codemirror/state';
import { vim } from '@replit/codemirror-vim';
import { keymap } from '@codemirror/view';
import { indentWithTab } from '@codemirror/commands';

export default class extends Controller<HTMLInputElement> {
    static values = {
        initialContent: { type: String, default: '' },
        language: String,
        editorId: String,
    };

    declare readonly initialContentValue: string;
    declare readonly editorIdValue: string;
    declare readonly languageValue: 'ruby' | 'yaml';

    declare editorState: EditorState;
    declare editorView: EditorView;

    connect() {
        const updateListener = EditorView.updateListener.of((update) => {
            (this.element as HTMLInputElement).value = update.state.doc.toString();
        });

        let language: any = null;
        if (this.languageValue === 'ruby') {
            language = ruby;
        } else if (this.languageValue === 'yaml') {
            language = yaml;
        }

        const params = new URLSearchParams(window.location.search);
        const vimEnabled = params.get('vim') !== null;

        this.editorState = EditorState.create({
            doc: this.initialContentValue || '',
            extensions: [
                ...(vimEnabled ? [vim()] : []),
                basicSetup,
                keymap.of([indentWithTab]),
                ...(language ? [StreamLanguage.define(language)] : []),
                updateListener,
            ],
        });

        this.editorView = new EditorView({
            state: this.editorState,
            parent: document.getElementById(this.editorIdValue)!,
        });
    }

}
