import { Application } from '@hotwired/stimulus';

const application = Application.start();

application.debug = window.env === 'development';
window.Stimulus = application;

// Generic controllers
// @ts-ignore
const genericControllers = import.meta.glob([
    '../controllers/**/*_controller.js',
    '../controllers/**/*_controller.ts',
], { eager: true });

for (let path in genericControllers) {
    let module = genericControllers[path] as any;
    let name = path
        .match(/\/(\w+)_controller\.(js|ts)$/)![1]
        .replaceAll('/', '-')
        .replaceAll('_', '-');

    application.register(name, module.default);
}

export { application };